var GET_DAY_NAME;
var GET_DAY_NAMES;
var GET_DAY_NAME_3;
var GET_DAY_NAMES_3;
var GET_MONTH_NAME;
var GET_MONTH_NAMES;
var GET_MONTH_NAME_3;
var GET_MONTH_NAMES_3;
!function() {
    // Internationalised date/day helpers
    var month_names;
    GET_MONTH_NAME = function(index) {
        if (month_names === undefined) {
            month_names = [
                gettext("January"),
                gettext("February"),
                pgettext("month name", "March"),
                gettext("April"),
                pgettext("month name", "May"),
                gettext("June"),
                gettext("July"),
                pgettext("month name", "August"),
                gettext("September"),
                gettext("October"),
                gettext("November"),
                gettext("December")
            ];
        }
        return month_names[index];
    };
    GET_MONTH_NAMES = function() {
        GET_MONTH_NAME();
        return month_names.slice();
    };
    var month_names_3;
    GET_MONTH_NAME_3 = function(index) {
        if (month_names_3 === undefined) {
            month_names_3 = [
                pgettext("month name 3 letters", "Jan"),
                pgettext("month name 3 letters", "Feb"),
                pgettext("month name 3 letters", "Mar"),
                pgettext("month name 3 letters", "Apr"),
                pgettext("month name 3 letters", "May"),
                pgettext("month name 3 letters", "Jun"),
                pgettext("month name 3 letters", "Jul"),
                pgettext("month name 3 letters", "Aug"),
                pgettext("month name 3 letters", "Sep"),
                pgettext("month name 3 letters", "Oct"),
                pgettext("month name 3 letters", "Nov"),
                pgettext("month name 3 letters", "Dec")
            ];
        }
        return month_names_3[index];
    };
    GET_MONTH_NAMES_3 = function() {
        GET_MONTH_NAME_3();
        return month_names_3.slice();
    };
    var day_names;
    GET_DAY_NAME = function(index) {
        if (day_names === undefined) {
            day_names = [
                gettext("Sunday"),
                gettext("Monday"),
                gettext("Tuesday"),
                gettext("Wednesday"),
                gettext("Thursday"),
                gettext("Friday"),
                gettext("Saturday")
            ];
        }
        return day_names[index];
    };
    GET_DAY_NAMES = function() {
        window.GET_DAY_NAME();
        return day_names.slice();
    };
    var day_names_3;
    GET_DAY_NAME_3 = function(index) {
        if (day_names_3 === undefined) {
            day_names_3 = [
                pgettext("day name 3 letters", "Sun"),
                pgettext("day name 3 letters", "Mon"),
                pgettext("day name 3 letters", "Tue"),
                pgettext("day name 3 letters", "Wed"),
                pgettext("day name 3 letters", "Thu"),
                pgettext("day name 3 letters", "Fri"),
                pgettext("day name 3 letters", "Sat")
            ];
        }
        return day_names_3[index];
    };
    GET_DAY_NAMES_3 = function() {
        GET_DAY_NAME_3();
        return day_names_3.slice();
    };
}();
