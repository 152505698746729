// Common 'next update' logic
window.pw_updateInfoToDisplayFromTimestamps = function(updatedTimes) {
    var now = Math.floor(Date.now()*0.001);
    var delay = 12*3600; // Expected time between forecasts
    // We wish to indicate when the last weather source will arrive of "this" forecast,
    // or the last weather source of the "next" forecast, and those things are a little
    // tricky to express in code.
    // A single forecast occurs during a window of time with length equal to delay,
    // where UTC midnight begins a new window.
    // e.g. if delay is 12 hours, 00-12UTC on any given day is one window; 12-24UTC is another.
    // If we ever do 3 forecasts per day, the day will be divided similarly into 3 windows. I hope.
    // So make cutoff the most recent time divisible by delay.
    var cutoff = delay * Math.floor(now / delay);
    // Note that jQuery.map will filter out null and undefined results & therefore null & undefined inputs in this case
    var relevantTimes = $j.map(updatedTimes, function(x){ return x < cutoff ? x : undefined; });
    if (relevantTimes.length === 0) {
        relevantTimes = $j.map(updatedTimes,function(x){ return x;});
    }
    var latest = Math.max.apply(null, relevantTimes); // Will throw an exception if relevantTimes has ended up empty
    var nextUpdateTime = Math.max(latest + delay, now); // Avoid showing a negative time until update :P
    var minutes = (nextUpdateTime - now) / 60.0;
    var hours = minutes / 60.0;
    if (hours > 1) {
        hours = Math.round(hours);
    } else {
        minutes = Math.round(minutes);
    }
    return {
        text:  hours < 1 ? interpolate(ngettext("Next update in %s minute", "Next update in %s minutes", minutes), [minutes])
            : interpolate(ngettext("Next update in %s hour", "Next update in %s hours", hours), [hours])
    };
};
